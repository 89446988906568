import axios, { AxiosRequestConfig, Method } from "axios";
// import * as AxiosLogger from "axios-logger";
import { API_URL } from "../constants";
import { ServerResponse } from "../state";

import i18next from "i18next";

import { onRequestAddToken, onResonposeWith401 } from "./interceptor/refresh";

export const axiosClient = (
  baseURL: string,
  isMultipart?: boolean,
  token?: string,
  lang?: string,
  isDevelopment?: boolean
) =>
  axios.create({
    baseURL: baseURL,
    headers: {
      "Content-type": isMultipart ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${token ?? localStorage.getItem("userToken")}`,
      "Accept-Language": lang ?? "en",
    },
  });

export const useGetHttpClient = (isMultipart?: boolean) => {
  const lang = i18next.language;
  const isMultipartForm = isMultipart || false;

  // const client = axiosClient(
  //   baseUrl,
  //   isMultipartForm,
  //   token,
  //   lang,
  //   isDevelopment
  // );
  // useEffect(() => {
  //   client.interceptors.request.use(AxiosLogger.requestLogger);
  //   client.interceptors.response.use(AxiosLogger.responseLogger);
  //   client.interceptors.response.use((res) => res, onResonposeWith401);
  // }, []);

  return <T>(url: string, method?: Method, config?: AxiosRequestConfig) =>
    httpClient
      .request({
        method: method ?? "GET",
        ...config,
        headers: {
          ...config?.headers,
          "Accept-Language": lang ?? "en",
          "Content-type": isMultipartForm
            ? "multipart/form-data"
            : "application/json",
        },
        url: url,
      })
      .then((res) => {
        const { data, error, message } = res.data as ServerResponse<T>;
        if (url.endsWith("integration/iwoca/latest_application"))
          return data as T;
        if (error) throw new Error(message ?? error);
        return data as T;
      });
};

// how to make arrow function with generic return type

export const httpClient =
  process.env.NODE_ENV === "development"
    ? axios.create({
        baseURL: API_URL,
        headers: {
          "Content-type": "application/json",
        },
      })
    : axios.create({
        baseURL: API_URL,
        headers: {
          "Content-type": "application/json",
        },
      });

export const httpClientWithMultipart =
  process.env.NODE_ENV === "development"
    ? axios.create({
        baseURL: API_URL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    : axios.create({
        baseURL: API_URL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
httpClient.interceptors.request.use(onRequestAddToken);
httpClientWithMultipart.interceptors.request.use(onRequestAddToken);
httpClient.interceptors.response.use((res) => res, onResonposeWith401);
httpClientWithMultipart.interceptors.response.use(
  (res) => res,
  onResonposeWith401
);

const responseModifier = (res: any) => {
  const { data, error, message } = res.data;
  if (error) {
    throw new Error(message);
  }

  if (Array.isArray(data)) {
    return data;
  }

  return { ...data, statusMessage: message };
};

export const swrMainFetcher = async <T>(
  url: string,
  token?: string,
  parmas?: RequestParmas,
  lang?: string
) => {
  if (parmas?.postBody) {
    if (parmas.isMultipart)
      return httpClientWithMultipart
        .post<ServerResponse<any>>(url, parmas?.postBody, {
          ...parmas.config,
          onDownloadProgress(progressEvent) {
            const uploadPercent = Math.round(
              (100 * progressEvent.loaded) / (progressEvent.total ?? 100)
            );
            parmas.onProgess && parmas.onProgess(uploadPercent);
          },
          headers: {
            Authorization: `Bearer ${
              token ?? localStorage.getItem("userToken")
            }`,
          },
        })
        .then(responseModifier)
        .catch((err) => {
          if (err.response) {
            throw new Error(err.response.data.message);
          }
          throw new Error(err.message);
        });
    return httpClient
      .post<ServerResponse<any>>(url, parmas.postBody, {
        ...parmas.config,
        headers: {
          Authorization: `Bearer ${token ?? localStorage.getItem("userToken")}`,
          "Accept-Language": lang ?? "en",
        },
      })
      .then(responseModifier)
      .catch((err) => {
        if (err.response) {
          throw new Error(err.response.data.message);
        }
        throw new Error(err.message);
      });
  }
  const res = await httpClient
    .get<ServerResponse<any>>(url, {
      ...parmas?.config,
      headers: {
        Authorization: `Bearer ${token ?? localStorage.getItem("userToken")}`,
        "Accept-Language": lang ?? "en",
        ...parmas?.config?.headers,
      },
    })
    .then(responseModifier)
    .catch((err) => {
      throw new Error(err.message);
    });
  return res;
};

export const swrFetcher = async <T>(
  url: string,
  token?: string,
  parmas?: RequestParmas,
  lang?: string
) => {
  const isMultipart = parmas?.isMultipart ?? false;
  const isPostRequest = parmas?.postBody ? true : false;

  let request = axiosClient(url, isMultipart, token, lang).get<
    ServerResponse<any>
  >(url, {
    ...parmas?.config,
  });

  if (isPostRequest) {
    request = axiosClient(url, isMultipart, token, lang).post<
      ServerResponse<any>
    >(url, parmas?.postBody, {
      ...parmas?.config,
      onDownloadProgress: isMultipart
        ? (progressEvent) => {
            const uploadPercent = Math.round(
              (100 * progressEvent.loaded) / (progressEvent.total ?? 100)
            );
            parmas?.onProgess && parmas.onProgess(uploadPercent);
          }
        : undefined,
    });
  }

  const result = await request
    .then((res) => {
      const { data, error, message } = res.data;
      if (error) {
        throw new Error(message);
      }
      return data as T;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
  return result;
};

interface RequestParmas {
  config?: AxiosRequestConfig<any> | undefined;
  postBody?: any;
  isMultipart?: boolean;
  onProgess?: (prog: number) => void;
}

export const getHttpClient = <T>(config: AxiosRequestConfig) => {
  return httpClient
    .request<T>(config)
    .then((res) => {
      const { data, error, message } = res.data as ServerResponse<T>;
      if (error) {
        throw new Error(message);
      }
      return data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export default httpClient;
