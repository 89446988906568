import { SliceType } from "./store";
import {
  ILoanCheckStatus,
  ICommonUserStatus,
} from "../modules/score/types/user";
import { IPaymentSechdule } from "../modules/shared/types";
import { IProfileMain } from "../pages/Profile/types";
import { IUserConfig } from "../components/hooks/useConfig";
import { resetUser } from "../../cypress/support/resetUser.command";
import { ILastApplicationResponse } from "../pages/NewHome/types";

export interface ServerResponse<T> {
  error: boolean;
  code: number;
  message: string;
  data: T | null;
}

export interface ResponseData<T> {
  error: boolean;
  code: number;
  message: string;
  data: T | null;
}

export interface UserInfo {
  token: string;
  id: string;
  username: string;
  refresh_token: string;
  expires_in: number;
}
export interface IUserSlice {
  score: Number;
  userInfo?: UserInfo;
  useStatus?: ICommonUserStatus;
  userLastApply: ILastApplicationResponse | any | null;
  paymentSechdule?: IPaymentSechdule;
  userLoanStatus?: ILoanCheckStatus;
  showDisbursed?: boolean;
  userConfig?: IUserConfig;
  showedDisbursedAlert: string[];
  profileInfo?: IProfileMain;
  showCPVCompleteAlert: string[];
  addtoCPVCompleteAlert: (by: string) => void;
  addedDisbursedAlert: (id: string) => void;
  setProfileInfo: (by: IProfileMain) => void;
  setShowDisbursed: (by: boolean) => void;
  setCheckLoanStatus: (by: ILoanCheckStatus) => void;
  setPaymentSchedule: (by: IPaymentSechdule) => void;
  setUser: (by?: UserInfo) => void;
  setUserStaus: (by: ICommonUserStatus) => void;
  setUserConfig: (by: IUserConfig) => void;
  setUserLastApply: (by: ILastApplicationResponse | null) => void;
  changeScore: (by: number) => void;
  resetUser: () => void;
}

const createUserSliceSlice: SliceType<IUserSlice> = (set, get) => ({
  score: get()?.useStatus?.score ?? 0,
  userInfo: undefined,
  useStatus: undefined,
  userLastApply: null,
  showDisbursed: true,
  showCPVCompleteAlert: [],
  addtoCPVCompleteAlert(by) {
    set((state) => void state.showCPVCompleteAlert.push(by));
  },
  setUserLastApply(by) {
    set((state) => void (state.userLastApply = by));
  },
  showedDisbursedAlert: [],
  addedDisbursedAlert(id) {
    set((state) => void state.showedDisbursedAlert.push(id));
  },
  setShowDisbursed(by) {
    set((state) => void (state.showDisbursed = by));
  },
  setProfileInfo(by) {
    set((state) => void (state.profileInfo = by));
  },
  setPaymentSchedule(by) {
    set((state) => void (state.paymentSechdule = by));
  },
  setCheckLoanStatus(by) {
    set((state) => void (state.userLoanStatus = by));
  },
  setUser: (by?: UserInfo) => set((state) => void (state.userInfo = by)),
  setUserConfig(by) {
    set((state) => void (state.userConfig = by));
  },
  setUserStaus(by) {
    set((state) => void (state.useStatus = by));
  },
  changeScore: (by) => set((state) => void (state.score = by)),
  resetUser: () => set((state) => void (state.userInfo = undefined)),
});

export default createUserSliceSlice;
