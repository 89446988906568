import { FC, PropsWithChildren } from "react";
// import { thankYouIcons } from '../../assets/animicons'
// import AdaptBox from '../../components/shared/AdaptBox'
import Lottie from "react-lottie";
import { useSearchParams } from "react-router-dom";
import thankanim from "../../assets/lottie/thank_you.json";
import useBoundStore from "../../state/store";
import { EKYCContext } from "../ekyc/types";

const defaultOptions = {
  loop: 2,
  autoplay: true,
  animationData: thankanim,
  // rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  // }
};
interface Props extends PropsWithChildren {
  animData?: any;
}
const ThankYou: FC<Props> = ({ children, animData = thankanim }) => {
  const [searchParamas] = useSearchParams();
  const { ekyccontext, userConfig } = useBoundStore();

  return (
    <div className="">
      <div className="flex items-center flex-col justify-center space-y-3">
        <Lottie options={{ ...defaultOptions, animationData: animData }} />
        {children || (
          <>
            <p className="text-gray-700 mx-6 text-center">
              {searchParamas.get("content")
                ? searchParamas.get("content")
                : ekyccontext === EKYCContext.CreditCard
                ? "LankaBangla will communicate with you soon to deliver the credit card "
                : "Your application is under process. You will get notification on decision soon. If you don’t get any update by 48 hours please call"}
            </p>
            <p className="text-gray-800 font-bold">
              {userConfig?.default?.hotline}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ThankYou;
